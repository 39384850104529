<template>
    <div id="opinion">
        <common-header></common-header>
        <h1 class="title">优化建议和意见反馈</h1>
        <div class="main">
            <div class="problem">
                <h2>*问题和意见</h2>
                <textarea v-model="contentTxt" placeholder="请详细描述您遇到的问题（10字以上），上传页面截图帮助更快解决"></textarea>
            </div>
            <div class="addImg-box">
                <h2>*添加图片（选填，提供问题截图）</h2>
                <!--                压缩图片-->
                <canvas ref="compress" style="display: none;"></canvas>
                <div class="addImg">
                    <input
                        type="file"
                        accept="image/*"
                        @change="changeImage($event)"
                        ref="avatarInput"
                        style="display:none"
                    />
                    <div class="pic_list_box">
                        <div class="pic_list" v-show="imgDatas.length">
                            <div v-for="(src,index) in imgDatas" :key="index">
                                <div
                                    class="upload-item"
                                    @mouseenter="enter(index)"
                                    @mouseleave="leave()"
                                >
                                    <div v-show="delShow == index" class="del">
                                        <span @click="delItem(index)">删除</span>
                                    </div>
                                    <img :src="src" srcset ref="updataImg" />
                                </div>
                            </div>
                        </div>
                        <div class="upload_btn" v-show="imgDatas.length!=9" @click="upLoad">
                            <i class="iconfont icon70ccff"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <div class="cancel" @click="cancel">取消</div>
                <div class="submit" @click="submit">提交</div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";

export default {
    name: "Opinion",
    components: { CommonHeader },
    data() {
        return {
            imgDatas: [], //上传图片数组
            delShow: null,
            contentTxt: "", // 反馈文本内容
        };
    },
    methods: {
        // 删除上传的图片
        delItem(index) {
            this.imgDatas.splice(index, 1);
        },
        // 鼠标滑过图片
        enter(index) {
            this.delShow = index;
        },
        // 鼠标移出图片
        leave() {
            this.delShow = null;
        },
        // 触发上传图片
        upLoad() {
            this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
        },
        // 上传图片
        changeImage(e) {
            console.log("e", e);
            let files = this.$refs.avatarInput.files;
            let that = this;
            let image = new Image();
            function readAndPreview(file) {
                if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
                    console.log("文件名", file.name);
                    let reader = new FileReader(); //新建一个FileReader对象
                    reader.onload = function () {
                        // 查找数组里是否已存在该数据 不存在则可以上传
                        // if (that.imgDatas.indexOf(this.result) === -1) {
                        //     that.imgDatas.push(this.result)
                        // }
                        console.log(this.result.length);

                        image.src = this.result;
                        //判断图片是否大于100k,不大于直接加入上传数组，反之压缩
                        if (this.result.length < 100 * 1024) {
                            that.imgDatas.push(this.result);
                        } else {
                            image.onload = function () {
                                //压缩处理
                                let data = that.compress(image);
                                console.log(data);
                                that.imgDatas.push(data);
                            };
                        }
                    };

                    reader.readAsDataURL(file); //将读取的文件转换成base64格式
                } else {
                    that.$message.warning({
                        message: "上传图片仅支持jpg/jpeg/png/gif格式",
                        center: true,
                    });
                }
            }
            if (files) {
                [].forEach.call(files, readAndPreview);
            }
            if (files.length === 0) {
                return;
            }
            this.delShow = null;
            // 清空value值
            e.target.value = "";
        },
        //图片压缩
        compress(img) {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            //瓦片canvas
            let tCanvas = document.createElement("canvas");
            let tctx = tCanvas.getContext("2d");
            let initSize = img.src.length;
            let width = img.width;
            let height = img.height;

            //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
            let ratio;
            if ((ratio = (width * height) / 4000000) > 1) {
                console.log("大于400万像素");
                ratio = Math.sqrt(ratio);
                width /= ratio;
                height /= ratio;
            } else {
                ratio = 1;
            }
            canvas.width = width;
            canvas.height = height;
            //        铺底色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            //如果图片像素大于100万则使用瓦片绘制
            let count;
            if ((count = (width * height) / 1000000) > 1) {
                count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
                //            计算每块瓦片的宽和高
                let nw = ~~(width / count);
                let nh = ~~(height / count);
                tCanvas.width = nw;
                tCanvas.height = nh;
                for (let i = 0; i < count; i++) {
                    for (let j = 0; j < count; j++) {
                        tctx.drawImage(
                            img,
                            i * nw * ratio,
                            j * nh * ratio,
                            nw * ratio,
                            nh * ratio,
                            0,
                            0,
                            nw,
                            nh
                        );
                        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                    }
                }
            } else {
                ctx.drawImage(img, 0, 0, width, height);
            }
            //进行最小压缩
            let ndata = canvas.toDataURL("image/jpeg", 0.1);
            console.log("压缩前：" + initSize);
            console.log("压缩后：" + ndata.length);
            console.log(
                "压缩率：" +
                    ~~((100 * (initSize - ndata.length)) / initSize) +
                    "%"
            );
            tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;

            return ndata;
        },
        //取消返回
        cancel() {
            this.$router.back();
        },
        //提交
        submit() {
            if (!this.contentTxt)
                return this.$message.warning({
                    message: "内容不能为空",
                    center: true,
                });
            const loading = this.$loading({
                lock: true,
                text: "上传中，请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(this.imgDatas);
            this.$http({
                url: this.api.otherFeedback,
                data: {
                    content: this.contentTxt,
                    imgs: JSON.stringify(this.imgDatas),
                },
            })
                .then((res) => {
                    console.log(res);
                    if (res.code === 0) {
                        loading.close();
                        this.$message.success({
                            message: res.msg,
                            center: true,
                        });
                        this.contentTxt = "";
                        this.imgDatas = [];
                    } else if (res.code === 1) {
                        this.$message.warning({
                            message: res.msg,
                            center: true,
                        });
                        loading.close();
                    } else if (res.code === -9) {
                        loading.close();
                    }
                })
                .catch((err) => console.log(err));
        },
    },
};
</script>

<style lang="less">
#opinion {
    min-width: 1200px;
    background: #f9f9f9;
    padding-bottom: 127px;

    .title {
        font-size: 36px;
        font-family: Source Han Sans SC Regular,
            Source Han Sans SC Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #70ccff;
        padding: 12px 0 20px 0;
    }

    .main {
        width: 1200px;
        height: 680px;
        background: #ffffff;
        margin: auto;
        padding: 30px 108px 72px 108px;
        box-sizing: border-box;

        .pic_list_box {
            display: flex;

            .upload_btn {
                width: 100px;
                height: 100px;
                background: #fff;
                margin: 20px 15px 20px 15px;
                cursor: pointer;
            }

            .pic_list {
                display: flex;

                .upload-item {
                    width: 100px;
                    height: 100px;
                    margin: 20px 0 20px 15px;
                    position: relative;
                    cursor: pointer;

                    .del {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        background: rgba(100, 100, 100, 0.9);
                        z-index: 9;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fffefe;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .problem {
            h2 {
                font-size: 16px;
                font-family: Source Han Sans SC Regular,
                    Source Han Sans SC Regular-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
            }

            textarea {
                width: 1046px;
                height: 140px;
                resize: none;
                margin-top: 40px;
                border: none;
                padding: 12px;
                box-sizing: border-box;
                vertical-align: baseline;
                caret-color: #878787;
                font-size: 16px;
                outline: none;
            }

            textarea::-webkit-input-placeholder {
                font-size: 17px;
                font-family: Source Han Sans SC Regular,
                    Source Han Sans SC Regular-Regular;
                font-weight: 400;
                text-align: left;
                color: #878787;
                line-height: 9px;
            }
        }

        .addImg-box {
            h2 {
                font-size: 16px;
                font-family: Source Han Sans SC Regular,
                    Source Han Sans SC Regular-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                padding: 20px 0;
            }

            .addImg {
                width: 1046px;
                height: 140px;
                background: #f8f8f8;
                text-align: center;

                .icon70ccff {
                    font-size: 54px;
                    color: #70ccff;
                    line-height: 100px;
                }
            }
        }

        .btn-box {
            margin-top: 108px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .cancel,
        .submit {
            width: 140px;
            height: 48px;
            background: #d3d3d3;
            border-radius: 4px;
            font-size: 18px;
            font-family: Source Han Sans SC Regular,
                Source Han Sans SC Regular-Regular;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            letter-spacing: 1px;
            line-height: 48px;
            cursor: pointer;
            display: inline-block;
        }

        .submit {
            background: #70ccff;
            margin-left: 326px;
        }
    }
}
</style>
